<template>
  <div>
    <div
      v-if="items.length > 0"
      :class="['strip', { 'black-theme' : !themeWhite }]"
      @select-item="onSelectItem()">
      <div class="strip-header">
        <h2 class="strip-category-title m-0">
          <span class="sr-only">
            {{ $t('category.stripHP.srOnly') }}
          </span>
          <router-link
            v-if="nature === 'COMPOSITE' || nature === 'TERMINAL'"
            v-tealium:click="$stats.meaCategory.onClickTitleStrip(category, category.isTop, page, userQualification)"
            :aria-label="category.title"
            :to="{
              name: routeName,
              params: { id: category.id, itemTitle: decoratedTitle }
            }"
            data-wptv="bandeau-titre">
            {{ category.title }}
          </router-link>
          <span v-else>
            {{ category.title }}
          </span>
        </h2>
        <div class="strip-display-all">
          <wptv-arrow-link
            v-if="nature === 'COMPOSITE' || nature === 'TERMINAL'"
            v-tealium:click="$stats.meaCategory.onClickAllButton(category, category.isTop, page, userQualification)"
            :aria-label="$t('general.meaCategorie.toutAfficher') + '  ' + category.title "
            :title="$t('general.meaCategorie.toutAfficher') + '  ' + category.title "
            :dark-mode="!themeWhite"
            :label="$t('general.meaCategorie.toutAfficher')"
            :to="{
              name: routeName,
              params: { id: category.id, itemTitle: decoratedTitle }
            }"
            data-wptv="bandeau-tout_afficher" />
        </div>
      </div>
      <div>
        <wptv-carousel
          ref="carousel"
          :responsive-rules="responsiveRules">
          <wptv-carousel-item
            v-for="item in items"
            ref="carouselItem"
            :key="item.id"
            v-tealium:click="$stats.meaCategory.onClickElement(category, item, page, userQualification)"
            data-wptv="bandeau-element_mea">
            <wptv-panel
              :hover-text="getHoverText(item)"
              :image-data="panelImageUrl(item)"
              :item="item"
              :link="to(item)"
              :progress-bar-disabled="setProgressBar(item, 3).disabled"
              :progress-bar-value="setProgressBar(item, 3).value"
              :promotion="getPromoInfo(item)"
              :rating="rating(item)"
              :rating-sr-only="ratingSr(item)"
              :subtitle-text="getSubtitleText(item)"
              :show-progress-bar="setProgressBar(item, 3).show"
              :class="[{ 'ob1-panel-inverse' : !themeWhite }]"
              @select-item="onSelectItem" />
          </wptv-carousel-item>
        </wptv-carousel>
      </div>
    </div>
    <div
      v-else
      class="empty">
      <slot name="empty" />
    </div>
  </div>
</template>

<script>
import { WptvArrowLink } from '@wptv/components/ui/arrowLink'
import { WptvPanel } from '@wptv/components/ui/panel'
import { WptvCarousel, WptvCarouselItem } from '@wptv/components/ui/carousel'
import { mapGetters } from 'vuex'
import PanelItemsMixin from '../panelItems/panelItems.mixin'
import { setItemImageData } from '../../shared/imageData.utils'
import _get from 'lodash/get'
import flexivod from '../flexivod'

export default {
  name: 'MeaCategory',
  components: {
    WptvArrowLink,
    WptvCarousel,
    WptvCarouselItem,
    WptvPanel
  },
  mixins: [PanelItemsMixin],
  props: {
    category: {
      type: Object,
      required: true
    },
    themeWhite: {
      type: Boolean,
      default: true
    },
    routeName: {
      type: String,
      default: 'category'
    }
  },
  computed: {
    ...mapGetters({
      activatedCoupon: 'user/getActivatedCoupon',
      userDiscounts: 'user/getUserDiscounts',
      articlesIds: 'user/getMyArticlesIds',
      userQualification: 'user/getUserQualification',
      findPlayHistoryById: 'user/findPlayHistoryById',
      page: 'meta/getPage'
    }),
    imageUrl () {
      return setItemImageData(this.items[0], {}, this.$config).pictures?.shift().source
    },
    items () {
      return flexivod.rejectNotEligiblePromoCategory(this.userDiscounts, this.activatedCoupon, _get(this.category, 'highlights.items', null))
    },
    nature () {
      return this.category.nature // TERMINAL, COMPOSITE ou HIGHLIGHTS_ONLY
    },
    favoriteFormat () {
      return _get(this.category, 'highlights.favoriteCoverFormat', null)
    },
    availableFormats () {
      return _get(this.category, 'highlights.availableCoverFormats', null)
    },
    responsiveRules () {
      if (this.favoriteFormat) {
        return this.favoriteFormat === 'COVER'
          ? { xsmall: 2, small: 2, medium: 4, large: 4, xlarge: 6, xxlarge: 8 }
          : { xsmall: 1, small: 1, medium: 2, large: 2, xlarge: 3, xxlarge: 4 }
      } else if (this.availableFormats) {
        return this.availableFormats[0] === 'COVER'
          ? { xsmall: 2, small: 2, medium: 4, large: 4, xlarge: 6, xxlarge: 8 }
          : { xsmall: 1, small: 1, medium: 2, large: 2, xlarge: 3, xxlarge: 4 }
      } else {
        return { xsmall: 1, small: 1, medium: 2, large: 2, xlarge: 3, xxlarge: 4 }
      }
    },
    decoratedTitle () {
      return this.category.title ? this.$options.filters.getDecoratedTitle(this.category.title) : null
    }
  },
  methods: {
    onSelectItem () {
      this.$store.commit('fip/pushHistory', this.items)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .black-theme a:focus:not(:focus-visible):not(:hover) {
  color: $white;
}
</style>
