<template>
  <main id="home">
    <h1 class="sr-only">
      {{ $t('general.vodAccessibility') }}
    </h1>
    <section
      v-if="Object.keys(highlights).length > 0"
      class="content">
      <herozone :highlights="highlights" />
    </section>
    <section
      v-for="(categoryStripMea, key) in getAllCategories"
      :key="key">
      <mea-top12
        v-if="categoryStripMea.isTop"
        :category="categoryStripMea"
        :theme-white="toggleMeaTheme(key)">
        <!-- (key+1) for the 1/3 black MEA and not starting on the first one -->
        <template #empty />
      </mea-top12>
      <mea-category
        v-else
        :category="categoryStripMea"
        :route-name="categoryStripMea.routeName"
        :theme-white="toggleMeaTheme(key)">
        <!-- (key+1) for the 1/3 black MEA and not starting on the first one -->
        <template #empty />
      </mea-category>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import Herozone from '../shared/herozone/Herozone'
import MeaCategory from '../shared/meaCategory/MeaCategory'
import MeaTop12 from '../shared/meaTop12/MeaTop12'
import seo from '@wptv/seo'
import { profileHasAccess } from '../../middleware/checkups'

export default {
  name: 'Home',
  components: {
    Herozone,
    MeaTop12,
    MeaCategory
  },
  layout: 'vod',
  asyncData ({ store, error }) {
    const allPromises = [
      store.dispatch('catalogs/getCatalogCategories'),
      store.dispatch('catalogs/getHerozone')
    ]
    // called only if user has full access for the strip "Ma liste"
    if (profileHasAccess(store.getters['core/getProfile'])) {
      allPromises.push(store.dispatch('bookmarks/getBookmarks'))
      allPromises.push(store.dispatch('alerts/getAlerts'))
    }
    return Promise.all(allPromises)
      .catch(() => error({ statusCode: 404 }))
  },
  head () {
    const socialMetaTags = seo.generateSocialMetaTags(
      this.canonical,
      '',
      '',
      '',
      '',
      '',
      this.$config.confetti.public.root.wassup,
      'VOD'
    )

    return {
      title: this.$t('home.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('home.description')
        },
        ...socialMetaTags,
        { name: 'navik::pageType', content: 'home' }
      ]
    }
  },
  meta: {
    oData: { page: 'home' }
  },
  computed: {
    ...mapGetters({
      categoriesStripMea: 'catalogs/getFilteredCategories',
      bookmarks: 'bookmarks/getBookmarks',
      alerts: 'alerts/getAlerts',
      userDiscounts: 'user/getUserDiscounts',
      activatedCoupon: 'user/getActivatedCoupon',
      userQualification: 'user/getUserQualification',
      applicationVariant: 'user/getApplicationVariant',
      highlights: 'catalogs/getHerozone',
      findPlayHistoryById: 'user/findPlayHistoryById'
    }),
    canonical () {
      return `${this.$config.confetti.public.root.wassup}/`
    },
    myList () {
      // setting necessary informations for the category 'ma liste'
      const myList = {
        routeName: 'myBookmarksAlerts',
        highlights: {
          ...this.availableAlerts,
          items: this.availableAlerts.items?.concat(this.filteredBookmarks).slice(0, 12)
        },
        id: 'MA_LISTE_CATEGORY_ID',
        name: 'Ma liste',
        nature: 'TERMINAL',
        title: 'Ma liste',
        type: 'CATEGORY',
        isTop: false
      }
      return myList
    },
    availableAlerts () {
      // only the alerts with status published
      return {
        ...this.alerts?.articles,
        items: this.alerts?.articles?.items?.filter(item => item.status === 'PUBLISHED').map((elem) => {
          // do not display the tag
          return { ...elem, isAlert: false }
        })
      }
    },
    filteredBookmarks () {
      // only the bookmarks not already bought or rent
      return this.bookmarks?.articles?.items?.filter(item => !this.findPlayHistoryById(item.id)) || []
    },
    getAllCategories () {
      const allCategories = [...this.categoriesStripMea]
      if (this.myList?.highlights?.items?.length > 0) {
        return allCategories.slice(0, 2).concat(this.myList).concat(allCategories.slice(2))
      }
      return allCategories
    }
  },
  methods: {
    toggleMeaTheme (key) {
      return ((key + 1) % 3) !== 0
    }
  }
}
</script>
