import _get from 'lodash/get'
import RouteUtils from '~/route.utils'
import { getRemainingRentTime } from '../getRemainingRentTime/getRemainingRentTime'
import { getPromoInfo } from '../getPromoInfo/getPromoInfo'
import FipUtils from '../../fip/shared/fip.utils'
import { setItemImageData } from '../../shared/imageData.utils'
import CONSTANTS from '../../../api.constants'

export default {
  methods: {
    /**
     * Return all detailed promo into from an item
     * @param item
     * @returns {{pastilInfo: {}, browsingInfo: {}}}
     */
    getPromoInfo (item) {
      const info = getPromoInfo(item, this.userDiscounts, this.activatedCoupon, this.articlesIds)
      // set a backgroung if there's something to display
      if (info.pastilInfo?.text) {
        info.pastilInfo.tagType = 'primary'
      }
      // to show the remaining time in the tag
      if (item.orderInfo) {
        const remainingText = this.getMaxWatchingDate(item)
        if (remainingText) {
          info.pastilInfo.text = remainingText
          info.pastilInfo.tagType = 'subscribed'
        }
      }
      // or if it's available for an alert
      if (this.alertPublished(item)) {
        info.pastilInfo.text = this.$t('myalerts.status.published')
        info.pastilInfo.tagType = 'subscribed'
      }
      return info
    },
    hasNotification (item) {
      return item.isAlert && _get(item, 'notification', '') === CONSTANTS.NOTIFICATIONS.NOTIFIED
    },
    alertPublished (item) {
      return item.isAlert && _get(item, 'status', '') === CONSTANTS.ALERT_STATUS.PUBLISHED
    },
    /**
     * Return label with remaining time
     * @param item
     * @returns {String}
     */
    getMaxWatchingDate (item) {
      if (item.videoType === CONSTANTS.RESOURCE_TYPES.MOVIE && item.orderInfo?.usage === CONSTANTS.PROMO.USAGE_RENT) {
        return this.$t('myvideos.remainingTime', getRemainingRentTime(item))
      }
    },
    to (item) {
      if (item.link?.match(/^https?:\/\/.*/)) {
        return item.link
      }
      return {
        name: RouteUtils.getPageRouteName(item.type, item.videoType),
        params: RouteUtils.getPageRouteParams(item, this.promo),
        link: item.link || ''
      }
    },
    duration (item) {
      return _get(item, 'duration', 1) * 60000
    },
    getPlayHistory (item) {
      return this.findPlayHistoryById(item?.id)
    },
    setProgressBar (item, minimumValue) {
      // FDE: [6019] reduce heavy category loading time by doing this after component display
      const playHistory = this.getPlayHistory(item)
      const timecode = playHistory?.timecode || 0
      return {
        timecode,
        show: item.type === CONSTANTS.RESOURCE_TYPES.VIDEO && !!playHistory,
        value: Math.max(timecode * 100 / this.duration(item), minimumValue),
        disabled: !playHistory || this.locationExpired(item)
      }
    },
    panelImageUrl (item) {
      return setItemImageData(item, { favoriteFormat: this.favoriteFormat, availableFormats: this.availableFormats }, this.$config)
    },
    rating (item) {
      return item.allocineRatings && item.allocineRatings.press && !this.isItemBought(item) ? item.allocineRatings.press.rating : -1
    },
    ratingSr (item) {
      const rating = this.rating(item)
      return rating > -1 ? this.$t('general.item.rating.srOnly', { rating: this.$options.filters.convertDotToComma(Math.round(rating * 10) / 10) }) : ''
    },
    locationExpired (item) {
      return FipUtils.isLocationExpired(this.$store, item.id)
    },
    getHoverText (item) {
      // get the minimal price including discounts
      const price = this.getMinimalPrice(item)

      // [FDE] TODO : remove this patch when tvm returns minimalCatalogPrice = -1 for prospected category and season
      const isProspect = item?.status === CONSTANTS.ALERT_STATUS.PROSPECTED
      const isSeason = item?.type === CONSTANTS.RESOURCE_TYPES.SEASON
      const isProgrammed = item?.status === CONSTANTS.ALERT_STATUS.PROGRAMMED

      if (price === 0 && !this.isItemBought(item) && !isProspect && !isSeason && !isProgrammed) {
        return this.$t('general.item.offert')
      } else if (price > 0 && !this.isItemBought(item)) {
        return this.$t('general.item.price', { price: this.$options.filters.convertDotToComma(price.toFixed(2)) })
      } else {
        return ''
      }
    },
    /**
     * get the lowest price between catalog price and discount price
     * @param {Object} item
     * @returns {number} minimal price
     */
    getMinimalPrice (item) {
      const userDiscountsIds = this.userDiscounts ? this.userDiscounts.map(e => e.eligibility === CONSTANTS.PROMO.SATISFIED ? e.id : '') : []
      let discountPrice
      if (item?.price?.discounts) {
        for (const e of item.price.discounts) {
          if (userDiscountsIds.includes(e?.discountId)) {
            discountPrice = e.minimalPrice
            break
          }
        }
      }
      const catalogPrice = item?.price?.minimalCatalogPrice

      // if discountPrice is defined, get the lowest price between discount and catalog prices
      return discountPrice ? Math.min(discountPrice, catalogPrice) : catalogPrice
    },
    isItemBought (item) {
      return this.$store.getters['user/isArticle'](item.id)
    },
    /**
     * @param {Number} duration in minutes
     * @param {Number} timecode in milliseconds
     * @returns {Number} of minutes
     */
    getTimeLeft (duration, timecode) {
      const timecodeMinutes = timecode / 60000
      return duration - timecodeMinutes.toFixed(0)
    },
    getSubtitleText (item) {
      const playHistory = this.getPlayHistory(item)
      // if the location is expired, show it
      if (this.locationExpired(item)) {
        return this.$t('panelItem.locationExpired')
        // if the item is a series, show series available
      } else if (item.type === CONSTANTS.RESOURCE_TYPES.SERIES) {
        return item.seasonsSumup
        // if the item is not bought, show the genre of the item
      } else if (!this.isItemBought(item)) {
        return item.genres ? this.$options.filters.capitalizeFLetter(item.genres[0]) : ''
        // if the item has already been started, show the time left to watch in minutes
      } else if (playHistory && item.duration) {
        return this.$t('panelItem.timeLeft', { time: this.getTimeLeft(item.duration, playHistory.timecode) })
      }
      // default show nothing
      return ''
    }
  }
}
