<template>
  <vod-item-link
    ref="topItemLink"
    :to="toLink"
    :type="item.type"
    :aria-label="item.title"
    class="top-item"
    @select-item="onSelectItem()">
    <div class="header">
      <span class="number">{{ topNumber | getTwoDigitNumber }}</span>
      <div class="image">
        <wptv-panel
          :hover-text="getHoverText(item)"
          :image-data="panelImageUrl(item)"
          :item="item"
          :link="to(item)"
          :progress-bar-disabled="setProgressBar(item, 3).disabled"
          :progress-bar-value="setProgressBar(item, 3).value"
          :promotion="getPromoInfo(item)"
          :rating="rating(item)"
          :rating-sr-only="ratingSr(item)"
          :subtitle-text="getSubtitleText(item)"
          :show-progress-bar="setProgressBar(item, 3).show"
          :is-top12="true"
          :class="[{ 'ob1-panel-inverse' : !themeWhite }]" />
      </div>
    </div>
    <div class="info">
      <h3 class="title m-0">
        {{ item.title }}
      </h3>
      <span class="genre">{{ getSubtitleText(item) | capitalizeFLetter }}</span>
    </div>
  </vod-item-link>
</template>
<script>
import { setItemImageData } from '../../shared/imageData.utils'
import { WptvPosterConfig } from '@wptv/components/ui/poster'
import VodItemLink from '../vodItem/vodItemLink/VodItemLink'
import PanelItemsMixin from '../../../../src/components/shared/panelItems/panelItems.mixin'
import { WptvPanel } from '@wptv/components/ui/panel'
import RouteUtils from '~/route.utils'
import { mapGetters } from 'vuex'

export default {
  name: 'TopItem',
  components: { VodItemLink, WptvPanel },
  mixins: [PanelItemsMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    indexNumber: {
      type: Number,
      required: true
    },
    themeWhite: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      activatedCoupon: 'user/getActivatedCoupon',
      articlesIds: 'user/getMyArticlesIds',
      userDiscounts: 'user/getUserDiscounts',
      findPlayHistoryById: 'user/findPlayHistoryById'
    }),
    genres () {
      return (Object.keys(this.item).length > 0 && this.item?.genres?.[0]) ? this.item.genres[0] : ''
    },
    imageData () {
      return setItemImageData(this.item, { favoriteFormat: 'COVER' }, this.$config)
    },
    topNumber () {
      return this.indexNumber + 1
    },
    ratio () {
      return WptvPosterConfig.RATIO.RATIO_3_4
    },
    favoriteFormat () {
      return 'COVER'
    },
    availableFormats () {
      return ['COVER']
    },
    toLink () {
      return {
        name: RouteUtils.getPageRouteName(this.item.type, this.item.videoType),
        params: RouteUtils.getPageRouteParams(this.item, this.promo),
        link: this.item.link || ''
      }
    }
  },
  methods: {
    onSelectItem () {
      this.$emit('select-item', this.item)
    }
  }
}
</script>
<style lang="scss" scoped>
  .top-item {
    display: flex;
    flex-direction: column;
    background-color: $darkgrey;

    &:hover,
    &:focus {
      background-color: $white;

      .info {
        color: $black;
      }
    }

    .header {
      display: flex;
      flex-direction: row;

      .number {
        font-weight: bold;
        color: $middlegrey;
        font-size: 60px;
        @include mediaQueries('font-size', (medium: 80px));
        display: flex;
        align-items: center;
        padding: 0 15px;
      }

      .image {
        margin: -10px -10px 0 0;
        height: 168px;
        @include mediaQueries('height', (medium: 254px));
        width: 126px;
        @include mediaQueries('width', (medium: 191px));
      }
    }

    .info {
      color: $white;
      margin: 15px;
      display: flex;
      flex-direction: column;

      .title {
        font-weight: bold;
        font-size: 14px;
        @include mediaQueries('font-size', (medium: 16px));
        width: 182px;
        @include mediaQueries('width', (medium: 269px));
        line-height: 1.43;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .genre {
        line-height: 1.38;
        font-size: 12px;
        @include mediaQueries('font-size', (medium: 14px));
      }
    }

  }
</style>
