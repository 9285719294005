<template>
  <div>
    <div
      v-if="items.length > 0"
      class="strip black-theme">
      <div class="img-theme">
        <img
          ref="fipImg"
          :src="backgroundImageUrl"
          :alt="items[0].title || ''">
      </div>
      <div
        v-if="showHeader"
        class="strip-header">
        <h2 class="m-0">
          <span class="sr-only">
            {{ $t('category.stripHP.srOnly') }}
          </span>
          <router-link
            v-if="nature === 'COMPOSITE' || nature === 'TERMINAL'"
            v-tealium:click="$stats.meaCategory.onClickTitleStrip(category, category.isTop, page, userQualification)"
            :aria-label="category.title"
            :to="{
              name: routeName,
              params: { id: category.id, itemTitle: decoratedTitle }
            }"
            data-wptv="bandeau-top-titre">
            {{ category.title }}
          </router-link>
          <span v-else>
            {{ category.title }}
          </span>
        </h2>
        <wptv-arrow-link
          v-if="nature === 'COMPOSITE' || nature === 'TERMINAL'"
          v-tealium:click="$stats.meaCategory.onClickAllButton(category, category.isTop, page, userQualification)"
          :aria-label="$t('general.meaCategorie.toutAfficher') + '  ' + category.title "
          :title="$t('general.meaCategorie.toutAfficher') + '  ' + category.title "
          :dark-mode="true"
          :label="$t('general.meaCategorie.toutAfficher')"
          :to="{
            name: routeName,
            params: { id: category.id, itemTitle: decoratedTitle }
          }"
          data-wptv="bandeau-tout_afficher" />
      </div>
      <div>
        <wptv-carousel
          ref="carousel"
          :responsive-rules="{}">
          <wptv-carousel-item
            v-for="(item, index) in items"
            ref="carouselItem"
            :key="item.id"
            v-tealium:click="$stats.meaCategory.onClickElement(category, item, page, userQualification)"
            class="top-item-padding"
            data-wptv="bandeau-element_mea">
            <top-item
              :index-number="index"
              :item="item"
              data-wptv="top-item"
              class="top-item-carrousel"
              @select-item="onSelectItem" />
          </wptv-carousel-item>
        </wptv-carousel>
      </div>
    </div>
    <div
      v-else
      class="empty">
      <slot name="empty" />
    </div>
  </div>
</template>

<script>
import { WptvArrowLink } from '@wptv/components/ui/arrowLink'
import { WptvCarousel, WptvCarouselItem } from '@wptv/components/ui/carousel'
import { mapGetters } from 'vuex'
import TopItem from '../topItem/topItem'
import { setItemImageData } from '../../shared/imageData.utils'
import flexivod from '../flexivod'
import CONSTANTS from '../../../api.constants'
import _get from 'lodash/get'

export default {
  name: 'MeaTop12',
  components: {
    WptvArrowLink,
    WptvCarousel,
    WptvCarouselItem,
    TopItem
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    routeName: {
      type: String,
      default: 'category'
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      activatedCoupon: 'user/getActivatedCoupon',
      userDiscounts: 'user/getUserDiscounts',
      articlesIds: 'user/getMyArticlesIds',
      userQualification: 'user/getUserQualification',
      page: 'meta/getPage'
    }),
    backgroundImageUrl () {
      const firstCover = this.items[0]?.covers?.find(cover => cover.format === CONSTANTS.IMAGES_FORMAT.BANNER)?.url

      // If it's not webp compliant then 1st banner otherwise nothing at all
      return setItemImageData(this.items[0], {}, this.$config).pictures?.shift()?.source || firstCover || ''
    },
    items () {
      return flexivod.rejectNotEligiblePromoCategory(this.userDiscounts, this.activatedCoupon, this.category?.highlights?.items)
    },
    nature () {
      return this.category.nature // TERMINAL, COMPOSITE ou HIGHLIGHTS_ONLY
    },
    decoratedTitle () {
      return this.category.title ? this.$options.filters.getDecoratedTitle(this.category.title) : null
    },
    favoriteFormat () {
      return _get(this.category, 'highlights.favoriteCoverFormat', null)
    },
    availableFormats () {
      return _get(this.category, 'highlights.availableCoverFormats', null)
    }
  },
  methods: {
    onSelectItem () {
      this.$store.commit('fip/pushHistory', this.items)
    }
  }
}
</script>
<style lang="scss" scoped>

  .top-item-padding {
    &:not(:last-child) {
      padding-right: 25px;
    }
  }

  .strip-header {
    position: relative;
  }

  .black-theme {
    position: relative;

    .img-theme {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: hidden;

       img {
         width: 100%;
         height: 100%;
         filter: blur(20px);
       }
    }
  }
  .top-item-padding{
    :focus:not(:hover){
      outline: auto;
    }
  }
</style>
