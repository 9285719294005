import { DateTime, Interval } from 'luxon'
import _get from 'lodash/get'

/**
 * get Remaining rent time for rented movies
 * used in mosaïc and meaCategory
 * @param item
 * @returns {*}
 */
function getRemainingRentTime (item) {
  const maxWatchingDate = DateTime.fromMillis(_get(item, 'orderInfo.maxWatchingDate', null))
  const durationInHours = Math.floor(Interval.fromDateTimes(DateTime.local(), maxWatchingDate).toDuration('hours').hours)
  const durationInMin = Math.floor(Interval.fromDateTimes(DateTime.local(), maxWatchingDate).toDuration('minutes').minutes)

  return durationInHours >= 2 ? { time: durationInHours, unit: 'h' } : { time: durationInMin, unit: 'min' }
}

export { getRemainingRentTime }
