<template>
  <div
    v-if="items.length > 0"
    class="vod-herozone strip black-theme"
    @select-item="onSelectItem()">
    <h2 class="sr-only">
      {{ hzSrOnly }}
    </h2>
    <div class="img-theme">
      <img
        ref="fipImg"
        :src="backgroundImageUrl"
        :alt="items[0].title || ''">
    </div>
    <!-- BANNER format -->
    <div v-if="format === IMAGE_TYPE.BANNER">
      <wptv-slider
        v-if="items && items.length !== 0"
        ref="slider"
        v-touch:swipe.right="prevSlide"
        v-touch:swipe.left="nextSlide"
        :interval="0"
        :link-to-bottom="false"
        :position-indicator="'counter'"
        class="hide-from-small">
        <wptv-slider-item
          v-for="item in items"
          :key="item.id">
          <wptv-panel
            v-tealium:click="$stats.herozone.onClickItem(category, item, pageForStats, userQualification)"
            :image-data="panelImageUrl(item)"
            :item="item"
            :link="to(item)"
            :progress-bar-disabled="setProgressBar(item, 3).disabled"
            :progress-bar-value="setProgressBar(item, 3).value"
            :promotion="getPromoInfo(item)"
            :is-herozone="true"
            :show-progress-bar="setProgressBar(item, 3).show"
            :subtitle-text="getSubtitleText(item)"
            :class="[{ 'ob1-panel-inverse' : !themeWhite }]"
            @select-item="onSelectItem" />
        </wptv-slider-item>
      </wptv-slider>

      <wptv-carousel
        ref="carousel"
        :responsive-rules="responsiveRules"
        theme="dark-img"
        class="show-from-small">
        <wptv-carousel-item
          v-for="item in items"
          :key="item.id">
          <wptv-panel
            v-tealium:click="$stats.herozone.onClickItem(category, item, pageForStats, userQualification)"
            :image-data="panelImageUrl(item)"
            :item="item"
            :link="to(item)"
            :progress-bar-disabled="setProgressBar(item, 3).disabled"
            :progress-bar-value="setProgressBar(item, 3).value"
            :promotion="getPromoInfo(item)"
            :is-herozone="true"
            :show-progress-bar="setProgressBar(item, 3).show"
            :subtitle-text="getSubtitleText(item)"
            :class="[{ 'ob1-panel-inverse' : !themeWhite }]"
            @select-item="onSelectItem" />
        </wptv-carousel-item>
      </wptv-carousel>
    </div>

    <!-- COVER format -->
    <div v-else>
      <wptv-carousel
        ref="carousel"
        :responsive-rules="responsiveRules"
        theme="dark"
        format="COVER">
        <wptv-carousel-item
          v-for="item in items"
          :key="item.id"
          @select-item="onSelectItem">
          <wptv-panel
            v-tealium:click="$stats.herozone.onClickItem(category, item, pageForStats, userQualification)"
            :image-data="panelImageUrl(item)"
            :item="item"
            :link="to(item)"
            :progress-bar-disabled="setProgressBar(item, 3).disabled"
            :progress-bar-value="setProgressBar(item, 3).value"
            :promotion="getPromoInfo(item)"
            :show-progress-bar="setProgressBar(item, 3).show"
            :is-herozone="true"
            :subtitle-text="getSubtitleText(item)"
            :class="[{ 'ob1-panel-inverse' : !themeWhite }]"
            @select-item="onSelectItem" />
        </wptv-carousel-item>
      </wptv-carousel>
    </div>
  </div>
</template>

<script>
import { WptvCarousel, WptvCarouselItem } from '@wptv/components/ui/carousel'
import { WptvSlider, WptvSliderItem } from '@wptv/components/ui/slider'
import { mapGetters } from 'vuex'
import { WptvPanel } from '@wptv/components/ui/panel'
import PanelItemsMixin from '../panelItems/panelItems.mixin'
import fipUtils from '../../fip/shared/fip.utils'
import flexivod from '../flexivod'
import CONSTANTS from '../../../api.constants'
import { setItemImageData } from '../../shared/imageData.utils'

export default {
  name: 'Herozone',
  components: {
    WptvCarousel,
    WptvCarouselItem,
    WptvSlider,
    WptvSliderItem,
    WptvPanel
  },
  mixins: [PanelItemsMixin],
  props: {
    highlights: {
      type: Object,
      required: true
    },
    themeWhite: {
      type: Boolean,
      default: true
    },
    category: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      IMAGE_TYPE: fipUtils.IMAGE_TYPE,
      pageForStats: ''
    }
  },
  computed: {
    ...mapGetters({
      activatedCoupon: 'user/getActivatedCoupon',
      userDiscounts: 'user/getUserDiscounts',
      articlesIds: 'user/getMyArticlesIds',
      page: 'meta/getPage',
      userQualification: 'user/getUserQualification',
      findPlayHistoryById: 'user/findPlayHistoryById'
    }),
    hzSrOnly () {
      return this.$t('category.herozone.srOnly', { categoryName: this.category?.name })
    },
    /**
     * Retrieve correct format, use favorite if possible, first available if present, else banner
     * @returns {*}
     */
    format () {
      const favoriteFormat = this.favoriteFormat
      const firstAvailableFormats = this.highlights?.favoriteCoverFormat[0] || fipUtils.IMAGE_TYPE.BANNER

      return [fipUtils.IMAGE_TYPE.COVER, fipUtils.IMAGE_TYPE.BANNER].includes(favoriteFormat) ? favoriteFormat : firstAvailableFormats
    },
    favoriteFormat () {
      return this.highlights?.favoriteCoverFormat
    },
    availableFormats () {
      return this.highlights?.availableCoverFormats
    },
    /**
     * responsive rules
     */
    responsiveRules () {
      if (this.format === fipUtils.IMAGE_TYPE.COVER) {
        return { xxsmall: 2, xsmall: 2, small: 2, medium: 4, large: 4, xlarge: 6, xxlarge: 6 }
      } else {
        return { xxsmall: 1, xsmall: 1, small: 1, medium: 2, large: 2, xlarge: 2, xxlarge: 3 }
      }
    },
    /**
     * Filter on items
     * @returns {*}
     */
    items () {
      return flexivod.rejectNotEligiblePromoCategory(this.userDiscounts, this.activatedCoupon, this.highlights.items)
    },
    backgroundImageUrl () {
      const firstCover = this.items[0]?.images?.find(cover => cover.format === CONSTANTS.IMAGES_FORMAT.BANNER)?.url
      const secondCover = this.items[0]?.covers?.find(cover => cover.format === CONSTANTS.IMAGES_FORMAT.BANNER)?.url
      // If it's not webp compliant then 1st banner otherwise nothing at all
      return setItemImageData(this.items[0], {}, this.$config).pictures?.shift()?.source || firstCover || secondCover || ''
    }
  },
  mounted () {
    this.pageForStats = this.page
  },
  methods: {
    /**
     * Called on next slide
     */
    nextSlide () {
      this.$refs.slider.next()
    },
    /**
     * Called on previous slide
     */
    prevSlide () {
      this.$refs.slider.prev()
    },
    /**
     * When item is selected; update fip history
     */
    onSelectItem () {
      this.$store.commit('fip/pushHistory', this.items)
    }
  }
}
</script>

<style lang="scss" scoped>
  // only for herozone, to have same padding space on top and bottom - US #6740
  ::v-deep .carousel-container:not(.light) {
    .scroll-container > div {
      padding-top: 30px;
    }
  }

  .slider {
    background: $black;

    .title {
      padding-left: 2.5%;
      margin-top: 0;
      padding-top: 8px;
      padding-right: 2.5%;
      width: auto;
      box-sizing: border-box;
    }

    .position-indicator.counter {
      background: $black;
      color: $lightgrey;
    }
  }
  .black-theme {
    position: relative;

    .img-theme {
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: hidden;

      img {
        width: 100%;
        height: 100%;
        filter: blur(20px);
      }
    }
  }
</style>
