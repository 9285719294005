<template>
  <div>
    <a
      v-if="type === 'LINK'"
      :href="to.link"
      target="blank">
      <slot />
    </a>
    <router-link
      v-else
      :to="to"
      @click.native="$emit('select-item')">
      <slot />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'VodItemLink',
  props: {
    to: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  a {
    height: 100%;
  }
</style>
