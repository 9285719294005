import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import getDecoratedTitle from '../../plugins/filters/getDecoratedTitle/getDecoratedTitle.filter'
import API_CONSTANT from '../../api.constants'
import { addDomain } from './api.utils'

/**
 * Apply SEO (with item title) to to image name in url
 * note: hopefully improve in the future when image apis are merged.
 * @param itemTitle
 * @param imageUrl
 * @returns {string|*}
 * @private
 */
function _getImageUrlWithSeo (itemTitle, imageUrl, isWebpCompliant) {
  if (itemTitle && imageUrl && isWebpCompliant) {
    const seoPart = getDecoratedTitle(itemTitle)

    let separatorParts = ''
    const urlWithoutImageName = imageUrl.split('/') // We have to split like this because sub repo could be between 'images/' and image name
    let imageName = urlWithoutImageName.pop() // We know the last part has always the image name

    if (imageName.includes('%2F')) { // Some url have sub repo, others dont so we need to know if %2F separator is here or not
      const splitted = imageName.split('%2F')
      imageName = splitted.pop() // last part is image name
      separatorParts = splitted.join('/')
    }

    const urlSeo = encodeURIComponent(`${separatorParts}/${seoPart}|${imageName}`)

    return `${urlWithoutImageName.join('/')}/${urlSeo}`
  } else {
    return imageUrl
  }
}

/**
 * retrieve image based on item data and by checking item data with a specific order
 * @param item
 * @param favoriteFormat
 * @param availableFormats
 * @param config
 * @private
 */
function _getImage (item, favoriteFormat, availableFormats, config) {
  // [WEBTV-5331] we need to keep tracks of images or covers origin to apply or not webp and seo
  const parsedImages = _get(item, 'images', []).map((image) => {
    return {
      ...image,
      source: API_CONSTANT.IMAGES_SOURCES.IMAGES,
      url: image.url.includes('http') ? image.url : addDomain(image.url, config)
    }
  })
  const parsedCovers = _get(item, 'covers', []).map((cover) => {
    return {
      ...cover,
      source: API_CONSTANT.IMAGES_SOURCES.COVERS,
      url: cover.url.includes('http') ? cover.url : addDomain(cover.url, config)
    }
  })
  const imagesCovers = [
    ...parsedImages,
    ...parsedCovers
  ]
  const format = [favoriteFormat, availableFormats[0], 'BANNER_1', 'COVER'].find(format => imagesCovers.some(item => item.format === format))
  const image = imagesCovers.find(item => item.format === format) || { url: '' }

  return { ...image }
}

/**
 * get image picture to adapt image fetching based on resolution
 * Add webp/quality to improve, add no webp fallback
 * @param image
 * @param isWebpCompliant
 * @returns {*}
 * @private
 */
function _getImagePictures (image, isWebpCompliant) {
  const pictures = []
  if (isWebpCompliant) {
    const imageConfigByFormat = API_CONSTANT.IMAGE_CONFIG[image.format] || []

    imageConfigByFormat.forEach(({ media, format }) => {
      const queryParamFormat = format ? `format=${format}x` : ''
      pictures.push({ media, source: `${image.url}?${queryParamFormat}&saveas=webp&saveasquality=70`, type: 'image/webp' })
      pictures.push({ media, source: `${image.url}?${queryParamFormat}&quality=70`, type: 'image/jpeg' })
    })
  }

  return pictures
}

// ------------------------------------------------------------

/**
 * set Item image data
 * @param item
 * @param favoriteFormat
 * @param availableFormats
 * @param config
 * @returns {{image, isProxyMedia: (boolean|*), pictures: [], ratio: (string)}}
 */
export function setItemImageData (item, { favoriteFormat, availableFormats = [] }, config) {
  const isProxyMedia = !_isEmpty(item.covers) || !_isEmpty(item.images)
  const image = _getImage(item, favoriteFormat, availableFormats || [], config)
  // [WEBTV-5331] images from "images" attribute are webp and seo compliant
  const isWebpCompliant = image.source === API_CONSTANT.IMAGES_SOURCES.IMAGES

  image.url = _getImageUrlWithSeo(item.title, image.url, isWebpCompliant)
  const pictures = _getImagePictures(image, isWebpCompliant)

  // Specific for posters
  const ratio = favoriteFormat === 'COVER' ? '3-4' : '16-9'

  return { isProxyMedia, ratio, image, pictures }
}
