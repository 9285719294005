import flexivod from '../flexivod'
import _includes from 'lodash/includes'
import constants from '../../../api.constants'

/**
 * Get promo info - check if promo or offered
 * @param item
 * @param userDiscounts
 * @param activatedCoupon
 * @param articles
 */
function getPromoInfo (item, userDiscounts, activatedCoupon, articlesIds) {
  let pastilInfo = {}
  let browsingInfo = {}

  if (_isPromo(item, userDiscounts, activatedCoupon, articlesIds)) {
    pastilInfo = {
      type: 'promotion',
      text: 'Promotion',
      name: 'present',
      color: '#ffd200'
    }

    if (item.type === constants.RESOURCE_TYPES.SERIES) {
      browsingInfo = {
        browsingSeasonId: _getFirstEligibleSeasonId(item, userDiscounts, activatedCoupon, articlesIds)
      }
    }
  }

  return { pastilInfo, browsingInfo }
}

// -------------------------------------------------------------------------------------------------

function _isPromo (item, userDiscounts, activatedCoupon, articlesIds) {
  return _getItemType(item) === constants.RESOURCE_TYPES.SERIES
    ? _getSeriePastille(item, userDiscounts, activatedCoupon, articlesIds)
    : _getItemPastille(item, userDiscounts, activatedCoupon, articlesIds)
}

function _isAlreadyBought (item, articlesIds = []) {
  return (articlesIds).includes(item.id)
}

function _isVideoType (item) {
  return _includes([
    constants.RESOURCE_TYPES.EPISODE,
    constants.RESOURCE_TYPES.MOVIE,
    constants.RESOURCE_TYPES.VIDEO
  ], _getItemType(item))
}

function _getItemType (item) {
  return item.videoType || item.type || ''
}

function _getItemPastille (item, userDiscounts, activatedCoupon, articlesIds) {
  return flexivod.pastillePromo(userDiscounts, activatedCoupon, item) &&
    !_isAlreadyBought(item, articlesIds)
}

function _getSeriePastille (item, userDiscounts, activatedCoupon, articlesIds) {
  return flexivod.pastillePromo(userDiscounts, activatedCoupon, item) &&
    _getFirstEligibleSeasonId(item, userDiscounts, activatedCoupon, articlesIds)
}

function _getFirstEligibleSeasonId (item, userDiscounts, activatedCoupon, articlesIds) {
  const elligiblePromos = flexivod.getItemEligiblePromos(userDiscounts, activatedCoupon, item)
  return flexivod.getFirstEligibleSeasonId(elligiblePromos, articlesIds)
}

// -------------------------------------------------------------------------------------------------

// TODO use https://github.com/jhnns/rewire in test instead of exporting private methods
export {
  getPromoInfo,
  _getItemType,
  _isVideoType,
  _isAlreadyBought,
  _isPromo,
  _getFirstEligibleSeasonId
}
